import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useCookies } from "./useCookies";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [cookie, setCookie] = useCookies("TopHrToken", null);

  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);
    setCookie(data.token);
    Cookies.set("logs", JSON.stringify(data.customerSetupLogs));
    navigate("/Registration", { replace: true , customerSetupLogs: data.customerSetupLogs});
  };

  const logout = () => {
    setUser(null);
    setCookie(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
