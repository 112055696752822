import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input, Message, toaster } from "rsuite";
import { SelectPicker, Stack } from "rsuite";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

const ConfirmDetails = ({ setStep }) => {
  const fileInputRef = React.useRef(null);
  const [stateArray, setStateArray] = React.useState([]);
  const [cityArray, setCityArray] = React.useState([]);
  const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
  const [stateId, setStateId] = React.useState(0);
  const [cityId, setCityId] = React.useState(0);
  const [industryTypeId, setIndustryTypeId] = React.useState(0);
  const [userData, setUserData] = React.useState({});
  const [image, setImage] = React.useState(null);
  const [placement, setPlacement] = React.useState("bottomEnd");

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );
  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Region delete successfully"} </strong>
    </Message>
  );
  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  // useEffect(() => {
  //  let userdata = Cookies.get("userData")
  //  console.log("userdata", JSON.parse(userdata))
  //  setUserData(JSON.parse(userdata))
  // }, []);

  const { isLoading: ipLoadingState } = useQuery(
    ["getStateByCountryId", industryTypeId],
    () => api.get("web/state/getStateByCountryId/1"),
    {
      onSuccess: (res) => {
        if (res.data.success === true) {
          setStateArray(res.data.state);
        }
      },
    }
  );
  const { isLoading: ipLoadingCity } = useQuery(
    [stateId],
    () => api.get(`web/city/getCityByStateId/${stateId}`),
    {
      onSuccess: (res) => {
        if (res.data.success === true) {
          setCityArray(res.data.city);
        }
      },
    }
  );

  const { data: IndustryTypes, isLoading: ipLoadingIndustryTypes } = useQuery(
    ["getIndustryTypes"],
    () => api.get(`web/industry/getIndustryTypes`),
    {
      onSuccess: (res) => {
        if (res.data.success === true) {
          setIndustryTypeArray(res.data.industryTypes);
        }
      },
    }
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      if (file.size <= 5000000) {
        // 5MB max size
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("File size exceeds 5MB");
      }
    } else {
      alert("Please select a valid image file");
    }
  };

  console.log("image", image);

  const confirmDetails = (data) => {
    AddCustomer({
      name: data.companyName,
      industryTypeId: industryTypeId,
      address: data.companyAddress,
      pincode: data.pincode,
      cityId: cityId,
      officialEmailAddress: data.officeEmailAddress,
      contactDetails: data.companyContactDetails,
      authorizedSignatory: data.authorizedSignature,
      gstNumber: data.gstNumber,
      panNumber: data.panNumber,
      esicCode: data.esicCode,
      epfoCode: data.epfoCode,
      helpdeskEmail: data.helpDeskEmail,
      displayImage: image,
      displayName: "image",
    });
  };

  const handleSubmit3 = () => {
    AddCustomer({
      name: "Techewewr",
      industryTypeId: 1,
      address: "123 bangalore",
      pincode: "123456",
      cityId: 530,
      officialEmailAddress: "contact@abccorp.com",
      contactDetails: "1234567890",
      authorizedSignatory: "Abhishek",
      gstNumber: "GST123456789",
      panNumber: "ABCDE1234F",
      esicCode: "ESIC1234567890123",
      epfoCode: "EPFO12345678901234567",
      helpdeskEmail: "helpdeskEmail@gmail.com",
      displayImage: image,
      displayName: "image",
    });
  };

  let token;
  useEffect(() => {
    token = Cookies.get("myToken");
  }, []);

  const { mutate: AddCustomer, isLoading } = useMutation(
    ["AddCustomer"],
    async (body) => await api.post(`mastercustomer/web/add`, body),
    {
      onSuccess: (res) => {
        // setStep((prev) => prev + 1);
        CustomerSetUpLogs({ level: "customer" });
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: CustomerSetUpLogs,
    isLoading: CustomerSetUpLogsRegionLoading,
    refetch: refetchCustomerSetUpLogsRegion,
  } = useMutation(
    ["updateCustomerSetUpLogs"],
    async (body) =>
      await api.put(`masterCustomer/updateCustomerSetUpLogs`, body),
    {
      onSuccess: (res) => {
        toaster.push(updateMessage, { placement, duration: 5000 });
        setStep((prev) => prev + 1);
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  useEffect(() => {
    setCityArray([]);
  }, [stateId]);

  return (
    <div>
     <div className="login-bg-new-img">
     <div className="login-page-modals new-modal2 new-width">
        <div className="login-inner-modal modal-3">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Confirm details!</h3>
          <p className="my-3">Please confirm the details displayed below.</p>

          <form onSubmit={handleSubmit(confirmDetails)}>
            <div>
              <div className="container-fluid p-0 ">
                <div className="row modal-body-2">
                  <div className="col-md-4">
                    <div className="logo-upload-section mt-2">
                      <div className="logo-upload">
                        {/* <img
                  src="/images/TopHRLogo.svg"
                  style={{ maxWidth: "70%" }}
                /> */}
                        {image && image ? (
                          <div style={{ marginTop: "10px" }}>
                            <img
                              className="logo-upload-img"
                              src={image}
                              alt="Selected"
                              // style={{ width: "200px", height: "auto" }}
                            />
                          </div>
                        ) : (
                          "LOGO"
                        )}
                      </div>
                      <div className="text-center">
                        <Button
                          onClick={handleButtonClick}
                          className="btn-large mt-3"
                          appearance="primary"
                          type="button"
                        >
                          Upload Logo
                        </Button>
                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label>Industry Type*</label>
                      <br />
                      <Controller
                        name="industryTypes"
                        control={control}
                        rules={{ required: "industry Types is required" }}
                        render={({ field }) => (
                          <SelectPicker
                            {...field}
                            data={industryTypeArray?.map((item) => {
                              return {
                                label: item.industryTypeName,
                                value: item.industryType,
                              };
                            })}
                            value={industryTypeId}
                            style={{ width: "153px" }}
                            onChange={(value) => {
                              field.onChange(value);
                              setIndustryTypeId(value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.industryTypes && errors.industryTypes.message}
                    </span>
                    <div className="mb-1">
                      <label>Company Name*</label>
                      <br />
                      {/* <Input placeholder="Enter department name" /> */}
                      <input
                        placeholder="Enter Company Name"
                        type="text"
                        className="form-control"
                        {...register("companyName", {
                          required: "Company Name is required",
                        })}
                      />
                    </div>
                    <span className=" d-block requird-color">
                      {errors.companyName && errors.companyName.message}
                    </span>
                    <div className="mb-1">
                      <label>Company Code*</label>
                      <br />
                      <input
                        placeholder="Enter Company Code"
                        className="form-control"
                        type="text"
                        {...register("companyCode", {
                          required: "Company Code is Required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.companyCode && errors.companyCode.message}
                    </span>
                    <div className="mb-1">
                      <label>Company Address*</label>
                      <br />
                      <input
                        placeholder="Enter Company Address"
                        type="text"
                        className="form-control"
                        {...register("companyAddress", {
                          required: "Company Address is required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.companyAddress && errors.companyAddress.message}
                    </span>
                    <div className="mb-1">
                      <label>Pincode*</label>
                      <br />
                      <input
                        placeholder="Enter pincode"
                        type="text"
                        className="form-control"
                        {...register("pincode", {
                          required: "Pincode is required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.pincode && errors.pincode.message}
                    </span>
                    <div>
                      <div className="row">
                        <div className="col-md-6 mb-1">
                          <label>State*</label>
                          <br />
                          <Controller
                            name="state"
                            control={control}
                            rules={{ required: "State is required" }}
                            render={({ field }) => (
                              <SelectPicker
                                {...field}
                                data={stateArray?.map((item) => {
                                  return {
                                    label: item.stateName,
                                    value: item.id,
                                  };
                                })}
                                value={stateId}
                                style={{ width: "153px" }}
                                onChange={(value) => {
                                  field.onChange(value);
                                  setStateId(value);
                                }}
                              />
                            )}
                          />
                          <span className="d-block requird-color">
                            {errors.state && errors.state.message}
                          </span>
                        </div>
                        <div className="col-md-6 mb-1">
                          <label>City*</label>
                          <br />
                          <Controller
                            name="city"
                            control={control}
                            rules={{ required: "City is required" }}
                            render={({ field }) => (
                              <SelectPicker
                                {...field}
                                data={cityArray?.map((item) => {
                                  return {
                                    label: item.cityName,
                                    value: item.id,
                                  };
                                })}
                                value={cityId}
                                style={{ width: "153px" }}
                                onChange={(value) => {
                                  field.onChange(value);
                                  setCityId(value);
                                }}
                              />
                            )}
                          />
                          <span className="d-block requird-color">
                            {errors.city && errors.city.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1">
                      <label>Company Official Email Address*</label>
                      <br />
                      <input
                        placeholder="Enter Official Email Address"
                        type="text"
                        className="form-control"
                        {...register("officeEmailAddress", {
                          required: "Company email address required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.officeEmailAddress &&
                        errors.officeEmailAddress.message}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-1">
                      <label>Company Contact Details*</label>
                      <br />
                      <input
                        placeholder="Enter Company Contact Details"
                        type="text"
                        className="form-control"
                        {...register("companyContactDetails", {
                          required: "Company Conatct Details is required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.companyContactDetails &&
                        errors.companyContactDetails.message}
                    </span>
                    <div className="mb-1">
                      <label>Authorized signatory*</label>
                      <br />
                      <input
                        placeholder="Authorized Signature"
                        type="text"
                        className="form-control"
                        {...register("authorizedSignature", {
                          required: "Authorized signature required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.authorizedSignature &&
                        errors.authorizedSignature.message}
                    </span>
                    <div className="mb-1">
                      <label>GST Number*</label>
                      <br />
                      <input
                        placeholder="Enter GST Number"
                        className="form-control"
                        {...register("gstNumber", {
                          required: "Gst Number is Required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.gstNumber && errors.gstNumber.message}
                    </span>
                    <div className="mb-1">
                      <label>PAN Number*</label>
                      <br />
                      <input
                        placeholder="Enter PAN Number"
                        className="form-control"
                        {...register("panNumber", {
                          required: "PAN number is required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.panNumber && errors.panNumber.message}
                    </span>
                    <div className="mb-1">
                      <label>ESIC Code*</label>
                      <br />
                      <input
                        placeholder="Enter ESIC Code"
                        className="form-control"
                        {...register("esicCode", {
                          required: "ESIC Code required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.esicCode && errors.esicCode.message}
                    </span>
                    <div className="mb-1">
                      <label>EPFO Code*</label>
                      <br />
                      <input
                        placeholder="Enter EPFO Code"
                        className="form-control"
                        {...register("epfoCode", {
                          required: "EPFO Code is required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.epfoCode && errors.epfoCode.message}
                    </span>
                    <div className="mb-1">
                      <label>Helpdesk Email Address*</label>
                      <br />
                      <input
                        placeholder="Enter Helpdesk Email Address"
                        className="form-control"
                        {...register("helpDeskEmail", {
                          required: "Helpdesk Email Address required",
                        })}
                      />
                    </div>
                    <span className="d-block requird-color">
                      {errors.helpDeskEmail && errors.helpDeskEmail.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  // onClick={() => setStep(4)}
                  type="submit"
                >
                  Confirm
                </Button>
              </div>
            </div>
            {/* <div>
              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  onClick={() => handleSubmit3()}
                  // type="submit"
                >
                  submit static data and go to next page
                </Button>
              </div>
            </div>
            <div>
              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  onClick={() => setStep((prev) => prev + 1)}
                  // type="submit"
                >
                  go to next page without submit data
                </Button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
     </div>
    </div>
  );
};

export default ConfirmDetails;
