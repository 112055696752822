import React, { useEffect } from "react";
import SetupOrganisation from "../components/Onboarding/SetupOrganisation";
import GSTNumberOfOrganisation from "../components/Onboarding/GSTNumberOfOrganisation";
import ConfirmDetails from "../components/Onboarding/ConfirmDetails";
import OrganisationStructure from "../components/Onboarding/OrganisationStructure";
import SetupDepartments from "../components/Onboarding/SetupDepartments";
import SetupDesignation from "../components/Onboarding/SetupDesignation";
import SetupLevels from "../components/SetupLevels";
import SetupRegions from "../components/Onboarding/SetupRegions";
import RegionsAndDepartmentsMapping from "../components/Onboarding/RegionsAndDepartmentsMapping";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Cookies from "js-cookie";

const Registration = ({ data }) => {
   const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [step, setStep] = React.useState(1);
  const [logsData, setLogsData] = React.useState(JSON.parse(Cookies.get("logs")))

  console.log("logsData", logsData);

  // useEffect(() => {
  //   if (
  //     logsData?.customer === true &&
  //     logsData?.region === false &&
  //     logsData?.department === false &&
  //     logsData?.regionDepartmentMapping === false &&
  //     logsData?.designation === false
  //   ) {
  //     console.log("customer");
  //     setStep(4);
  //   } else if (
  //     logsData?.customer === true &&
  //     logsData?.region === true &&
  //     logsData?.department === false &&
  //     logsData?.regionDepartmentMapping === false &&
  //     logsData?.designation === false
  //   ) {
  //     console.log("region");
  //     setStep(7);
  //   } else if (
  //     logsData?.customer === true &&
  //     logsData?.region === true &&
  //     logsData?.department === true &&
  //     logsData?.regionDepartmentMapping === false &&
  //     logsData?.designation === false
  //   ) {
  //     console.log("department");
  //     setStep(9);
  //   } else if (
  //     logsData?.customer === true &&
  //     logsData?.region === true &&
  //     logsData?.department === true &&
  //     logsData?.regionDepartmentMapping === true &&
  //     logsData?.designation === false
  //   ) {
  //     console.log("regionDepartmentMapping");
  //     setStep(11);
  //   } else if (
  //     logsData?.customer === true &&
  //     logsData?.region === true &&
  //     logsData?.department === true &&
  //     logsData?.regionDepartmentMapping === true &&
  //     logsData?.designation === true
  //   ) {
  //      navigate("/dashboard");
  //   } else if(
  //     logsData?.customer === false &&
  //     logsData?.region === false &&
  //     logsData?.department === false &&
  //     logsData?.regionDepartmentMapping === false &&
  //     logsData?.designation === false
  //   ) {
  //     setStep(1);
  //   }
  // }, [logsData]);

  console.log("data", data);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {(() => {
        if (step === 1) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 2) {
          return <GSTNumberOfOrganisation step={step} setStep={setStep} />;
        } else if (step === 3) {
          return <ConfirmDetails step={step} setStep={setStep} />;
        } else if (step === 4) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 5) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 6) {
          return <SetupRegions step={step} setStep={setStep} />;
        } else if (step === 7) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 8) {
          return <SetupDepartments step={step} setStep={setStep} />;
        } else if (step === 9) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 10) {
          return <RegionsAndDepartmentsMapping step={step} setStep={setStep} />;
        } else if (step === 11) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 12) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 13) {
          return <SetupDesignation step={step} setStep={setStep} />;
        } else if (step === 14 || step === 15) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        }
      })()}

      {/* <SetupOrganisation/>
        <GSTNumberOfOrganisation/>
        <ConfirmDetails/>
        <OrganisationStructure/>
        <OrganisationStructureTwo/>
        <SetupDepartments/>
        <SetupDesignation/> */}
      {/* <SetupLevels /> */}

      {/* <SetupDepartments/> */}
      {/* <SetupRegions/> */}
      {/* <RegionsAndDepartmentsMapping/> */}
    </div>
  );
};

export default Registration;
