import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  ButtonToolbar,
  Input,
  Loader,
  Message,
  Modal,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
// import Accordion,{  Placeholder } from 'rsuite';
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import ConfirmDelete from "./ConfirmDelete";
import { set } from "js-cookie";

const SetupRegions = ({ step, setStep }) => {
  // const [regionsArray, setRegionsArray] = useState([]);
  const toaster = useToaster();
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [regionParentId, setRegionParentId] = useState(0);
  const [newRegionArray, setNewRegionArray] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);
  const [editedName, setEditedName] = useState("");
  const [parentIdForUpdate, setParentIdForUpdate] = useState(0);

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );
  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Region delete successfully"} </strong>
    </Message>
  );
  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: regionsArray,
    isLoading: getAllRegionLoading,
    refetch: refetchApi,
  } = useQuery(
    ["getAllRegion"],
    () =>
      api.get(
        "masterRegion/web/getAllRegion?pageNo=1&pageSize=1000&isAscending=false&sortBy&filterBy&searchValue"
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.paginationResponse.content,
    }
  );

  const handleAddRegions = (data) => {
    addRegions({
      name: data.regionName,
      parentId:
        regionsArray == [] ? 0 : regionParentId != 0 ? regionParentId : 0,
    });
  };

  const { mutate: addRegions, isLoading: addRegionLoading } = useMutation(
    ["addRegion"],
    async (body) => await api.post(`masterRegion/web/addRegion`, body),
    {
      onSuccess: (res) => {
        refetchApi();
        setRegionParentId(0);
        reset();
        toaster.push(addMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: deleteRegion,
    isLoading: deleteRegionLoading,
    refetch: refetchdeleteRegion,
  } = useMutation(
    ["deleteRegion"],
    async (body) => await api.delete(`masterRegion/web/deleteRegion/${body}`),
    {
      onSuccess: (res) => {
        refetchApi();
        toaster.push(deleteMessage, { placement, duration: 5000 });
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: updateRegion,
    isLoading: updateRegionLoading,
    refetch: refetchupdateRegion,
  } = useMutation(
    ["updateRegion"],
    async (body) =>
      await api.put(`masterRegion/web/updateRegion/${editId}`, body),
    {
      onSuccess: (res) => {
        refetchApi();
        toaster.push(updateMessage, { placement, duration: 5000 });
        setParentIdForUpdate(0);
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const {
    mutate: CustomerSetUpLogs,
    isLoading: CustomerSetUpLogsRegionLoading,
    refetch: refetchCustomerSetUpLogsRegion,
  } = useMutation(
    ["updateCustomerSetUpLogs"],
    async (body) =>
      await api.put(`masterCustomer/updateCustomerSetUpLogs`, body),
    {
      onSuccess: (res) => {
        toaster.push(updateMessage, { placement, duration: 5000 });
        setStep((prev) => prev + 1);
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  const handleDeleteRegion = () => {
    setOpenDeleteModal(false);
    deleteRegion(deleteObject.id);
  };

  useEffect(() => {
    if (regionsArray?.length > 0) {
      console.log("transformData", transformData(regionsArray));
      setNewRegionArray(transformData(regionsArray));
    }
  }, [regionsArray]);

  const handleUpdate = () => {
    console.log("region", editId);
    console.log("editedName", editedName);
    updateRegion({ name: editedName, parentId: parentIdForUpdate });
  };

  console.log("newRegionArray", newRegionArray);

  const handleBlur = () => {
    setIsEdit(false);
    handleUpdate();
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEdit(false);
      handleUpdate();
    }
  };

  const transformData = (data) => {
    // Create a map to hold the items by their ID
    const map = {};
    data.forEach((item) => {
      map[item.id] = { ...item, children: [] };
    });

    // Iterate through the items and populate the children arrays
    const rootItems = [];
    data.forEach((item) => {
      if (item.parent) {
        const parent = map[item.parent.id];
        if (parent) {
          parent.children.push(map[item.id]);
        }
      } else {
        rootItems.push(map[item.id]);
      }
    });

    return rootItems;
  };

  console.log("parent", regionParentId);

  // AccordionItem component
  const AccordionItem = ({ item }) => {
    return (
      <Accordion.Item eventKey={item.id.toString()}>
        <Accordion.Header className={ item.children.length > 0 ? "" : 'hideIcon' }>
          <div className="strip mb-1">
            <div className="d-flex align-items-center">
              {/* <div className="counting">{item.id}</div> */}
              <div>
                <p className="data-text">
                  {isEdit && editId == item.id ? (
                    <Input
                      value={editedName}
                      onBlur={handleBlur}
                      onKeyDown={handleEnterKeyPress}
                      autoFocus={true}

                      onChange={(value) => setEditedName(value)}
                    />
                  ) : (
                    item.name
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div>
                <FaPlus className="strip-icon" />
              </div> */}
              <div
                onClick={() => {
                  setEditedName(item.name);
                  setIsEdit(true);
                  setEditId(item.id);
                  setParentIdForUpdate(0);
                }}
              >
                <MdEdit className="strip-icon" />
              </div>
              <div
                onClick={() => {
                  setDeleteObject(item);
                  setOpenDeleteModal(true);
                }}
              >
                <TiDelete />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {item.children.length > 0 && (
            <Accordion>
              {item.children.map((child) => {
                if (child.children.length > 0) {
                  return <AccordionItem key={child.id} item={child} />;
                } else {
                  return (
                    <div className="strip-accodion mb-1">
                      <div className="d-flex align-items-center">
                        {/* <div className="counting">{child.id}</div> */}
                        <div>
                          <p className="data-text">
                            {" "}
                            {isEdit && editId == child.id ? (
                              <Input
                                value={editedName}
                                onBlur={handleBlur}
                                onKeyDown={handleEnterKeyPress}
                                autoFocus={true}
                                onChange={(value) => setEditedName(value)}
                              />
                            ) : (
                              child.name
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        {/* <div>
                          <FaPlus className="strip-icon" />
                        </div> */}
                        <div
                          onClick={() => {
                            setEditedName(item.name);
                            setIsEdit(true);
                            setEditId(child.id);
                            setParentIdForUpdate(child?.parent?.id);
                          }}
                        >
                          <MdEdit className="strip-icon" />
                        </div>
                        <div
                          onClick={() => {
                            setDeleteObject(child);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <TiDelete />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Accordion>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  // Main Accordion component
  const NestedAccordion = ({ data }) => {
    return (
      <Accordion defaultActiveKey="0">
        {data.map((item) => (
          <AccordionItem key={item.id} item={item} />
        ))}
      </Accordion>
    );
  };

  return (
    <div className="main-div">
      {getAllRegionLoading || addRegionLoading || deleteRegionLoading ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
     <div className="login-bg-new-img">
     <div className="login-page-modals new-width">
        <div className="login-inner-modal modal-5">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Setup Regions </h3>
          <p className="my-3">Create Regions as per your organisation</p>

          <form onSubmit={handleSubmit(handleAddRegions)}>
            <div>
              <div className="align-items-end d-flex">
                <div className="container-fluid p-0">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className=" new-margin">
                        <label>Regions Name </label>

                        <input
                          placeholder="Enter Regions name"
                          style={{ width: "150px !important" }}
                          type="text"
                          {...register("regionName", {
                            required: "Region Name is required",
                          })}
                        />
                      </div>
                      <span className=" d-block requird-color">
                        {errors.regionName && errors.regionName.message}
                      </span>
                    </div>

                    <div className="col-md-5">
                      <div className=" new-margin">
                        <label>Parent Regions </label>
                        <SelectPicker
                          data={regionsArray?.map((item) => {
                            return { label: item.name, value: item.id };
                          })}
                          searchable={false}
                          style={{ width: 224 }}
                          placeholder="Select Region"
                          value={regionParentId}
                          onChange={(val) => {
                            setRegionParentId(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <Button
                          className="btn-large"
                          style={{ margin: "0 -10px" }}
                          appearance="primary"
                          type="submit"
                        >
                          Add New
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="organisation-structure-data">
                  <p className="data-text mb-2 mt-4">List of Regions</p>

                  <div className="scroll-container">
                    <div className="scroll-content">
                      <NestedAccordion data={newRegionArray} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  // onClick={() => setStep((prev) => prev + 1)}
                  onClick={() => CustomerSetUpLogs({ level: "region" })}
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
          <ConfirmDelete
            deleteObject={deleteObject}
            handledeleteObject={handleDeleteRegion}
            setOpenDeleteModal={setOpenDeleteModal}
            openDeleteModal={openDeleteModal}
          />
        </div>
      </div>
     </div>
    </div>
  );
};

export default SetupRegions;
