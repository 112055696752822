import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  Input,
  Loader,
  Message,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
// import Accordion,{  Placeholder } from 'rsuite';

const SetupDesignation = ({ step, setStep }) => {
  const toaster = useToaster();
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [newData, setNewData] = useState([]);
  const [designationParentId, setDesignationParentId] = useState(0);
  const [newDesignationArray, setNewDesignationArray] = useState([]);

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Designation added successfully"} </strong>
    </Message>
  );

  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Designation deleted successfully"} </strong>
    </Message>
  );

    const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  console.log("stepdd", step);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const { data: designationArray, isLoading: getAllDesignation ,refetch:refetchApi } = useQuery(
    ["getAllDesignation"],
    () =>
      api.get(
        "masterDesignation/web/getAll?pageNo=1&pageSize=1000&isAscending=false&sortBy&filterBy&searchValue"
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.paginationResponse.content,
    }
  );

  const handleAddDesignation = (data) => {
    console.log("data999999999", data);
    addDesignation({
      name: data?.designationName,
      parentId:
        designationArray == []
          ? 0
          : designationParentId != 0
          ? designationParentId
          : 0,
    });
  };

  const { mutate: addDesignation, isLoading: addDesignationLoading } =
    useMutation(
      ["addDesignation"],
      async (body) => await api.post(`masterDesignation/web/add`, body),
      {
        onSuccess: (res) => {
          refetchApi();
          setDesignationParentId(0);
          reset();
          toaster.push(addMessage, { placement, duration: 5000 });
          // setStep(4);
        },
        onError: (err) => {
          console.log("error", err.response);
        },
      }
    );

  const { mutate: deleteDesignation, isLoading: deleteDesignationById } =
    useMutation(
      ["deleteDesignationById"],
      async (body) => await api.delete(`masterDesignation/web/delete/${body}`),
      {
        onSuccess: (res) => {
          refetchApi();
          toaster.push(deleteMessage, { placement, duration: 5000 });
          // setStep(4);
        },
        onError: (err) => {
          console.log("error", err.response);
        },
      }
    );

  const handleDeleteDesignation = (id) => {
    deleteDesignation(id);
  };


  const {
    mutate: CustomerSetUpLogs,
    isLoading: CustomerSetUpLogsRegionLoading,
    refetch: refetchCustomerSetUpLogsRegion,
  } = useMutation(
    ["updateCustomerSetUpLogs"],
    async (body) =>
      await api.put(`masterCustomer/updateCustomerSetUpLogs`,body),
    {
      onSuccess: (res) => {
        
        toaster.push(updateMessage, { placement, duration: 5000 });
        setStep((prev) => prev + 1)
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );


  useEffect(() => {
    if (designationArray?.length > 0) {
      console.log("transformData", transformData(designationArray));
      setNewDesignationArray(transformData(designationArray));
    }
  }, [designationArray]);

  const transformData = (data) => {
    // Create a map to hold the items by their ID
    const map = {};
    data.forEach((item) => {
      map[item.id] = { ...item, children: [] };
    });

    // Iterate through the items and populate the children arrays
    const rootItems = [];
    data.forEach((item) => {
      if (item.parent) {
        const parent = map[item.parent.id];
        if (parent) {
          parent.children.push(map[item.id]);
        }
      } else {
        rootItems.push(map[item.id]);
      }
    });

    return rootItems;
  };

  // AccordionItem component
  const AccordionItem = ({ item }) => {
    return (
      <Accordion.Item eventKey={item.id.toString()}>
        <Accordion.Header className={ item.children.length > 0 ? "" : 'hideIcon' }>
          <div className="strip mb-1">
            <div className="d-flex align-items-center">
              {/* <div className="counting">{item.id}</div> */}
              <div>
                <p className="data-text">{item.name}</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div>
                <FaPlus className="strip-icon" />
              </div> */}
              <div>
                <MdEdit className="strip-icon" />
              </div>
              <div onClick={() => handleDeleteDesignation(item.id)}>
                <TiDelete />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {item.children.length > 0 && (
            <Accordion>
              {item.children.map((child) => {
                if (child.children.length > 0) {
                  return <AccordionItem key={child.id} item={child} />;
                } else {
                  return (
                    <div className="strip-accodion mb-1">
                      <div className="d-flex align-items-center">
                        {/* <div className="counting">{child.id}</div> */}
                        <div>
                          <p className="data-text">{child.name}</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        {/* <div>
                          <FaPlus className="strip-icon" />
                        </div> */}
                        <div>
                          <MdEdit className="strip-icon" />
                        </div>
                        <div onClick={() => handleDeleteDesignation(child.id)}>
                          <TiDelete />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Accordion>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  // Main Accordion component
  const NestedAccordion = ({ data }) => {
    return (
      <Accordion defaultActiveKey="0">
        {data.map((item) => (
          <AccordionItem key={item.id} item={item} />
        ))}
      </Accordion>
    );
  };

  console.log("designationArray", designationArray);

  return (
    <div>
      {getAllDesignation || addDesignationLoading || deleteDesignationById ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
      <div className="login-page-modals new-width">
        <div className="login-inner-modal modal-5">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Setup Designation </h3>
          <p className="my-3">Create Designation as per your organisation</p>

          <form onSubmit={handleSubmit(handleAddDesignation)}>
            <div>
              <div className="align-items-end d-flex">
                <div className="container-fluid">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className="new-margin">
                        <label>Designation Name </label>

                        <input
                          placeholder="Enter Designation name"
                          style={{ width: "150px !important" }}
                          type="text"
                          {...register("designationName", {
                            required: "Designation name is Required",
                          })}
                        />
                      </div>
                      <span className=" d-block requird-color">
                        {errors.designationName && errors.designationName.message}
                      </span>
                    </div>
                    <div className="col-md-5">
                      <div className=" new-margin">
                        <label>Parent Designation </label>
                        <SelectPicker
                          data={designationArray?.map((item) => {
                            return { label: item.name, value: item.id };
                          })}
                          searchable={false}
                          style={{ width: 224 }}
                          placeholder="Select Designation"
                          value={designationParentId}
                          onChange={(val) => {
                            console.log("valrr", val);
                            setDesignationParentId(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <Button
                          className="btn-large"
                          style={{ margin: "0 -10px" }}
                          appearance="primary"
                          type="submit"
                        >
                          Add New
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="organisation-structure-data">
                  <p className="data-text mb-2 mt-4">List of Designation</p>
                  <div className="scroll-container">
                    <div className="scroll-content">
                      <NestedAccordion data={newDesignationArray} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  onClick={() => CustomerSetUpLogs({level:"designation"})}
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetupDesignation;
