import { LiaUserClockSolid } from "react-icons/lia";
import { BsFileEarmarkText } from "react-icons/bs";

export const navAdminPages = [
   
    {
        title:'Compliance',
        icon:<BsFileEarmarkText className="new-icn"/>,
        menu: [
            {
              title: "Opening Balance",
              link: "/opening-balance",
            },
            {
              title: "Account Master",
              link: "/account-master",
            },
          ],
    }
]