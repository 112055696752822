import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  // baseURL: "http://localhost:8080/api/",
  // baseURL: "https://app.tophr.co.in/api/",
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = Cookies.get("TopHrToken");
  if (token) {

    // config.headers.append(
    //   "Access-Control-Allow-Origin",
    //   "https://app.tophr.co.in/api/"
    // );
    // config.headers.append("Access-Control-Allow-Credentials", "true");

    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    console.log("response", response);
    return response;
  },
  (error) => {
    console.log("error", error);
    if (error.response.status === 401) {
      localStorage.removeItem("userData");
      Cookies.remove("myToken");
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
