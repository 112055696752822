/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input } from "rsuite";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";

const OrganisationStructure = ({ step, setStep }) => {
  return (
    <div>
     <div className="login-bg-new-img">
     <div className="login-page-modals">
        <div className="login-inner-modal modal-4">
          <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">Organisation Structure </h3>
          <p className="my-3">
            Please configure your organisational structure to continue.
          </p>
          <div>
            {step === 5 ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 7 ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="data-text color-light"> COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 9 ? (
              <div>
                <p className="data-text color-light">YET TO COMPLETE</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="data-text color-light"> COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : step === 11 ? (
              <div>
                <p className="data-text color-light">COMPLETED</p>
                <div className="container-fluid p-0">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Regions</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 7 || step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">Departments</p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 9 || step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="strip">
                        <div>
                          <IoSettings
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="data-text color-light">
                              Structure Mapping
                            </p>
                          </div>
                        </div>
                        <div>
                          <IoCheckmarkCircle
                            style={
                              step === 11
                                ? { color: "green" }
                                : { color: "gray" }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div>
              <p className="data-text color-light">YET TO COMPLETE</p>
              <div className="container-fluid p-0">
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="strip">
                      <div>
                        <IoSettings
                          style={
                            step ===7|| step=== 9 || step === 11 ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center">
                        <div>
                          <p className="data-text color-light">Regions</p>
                        </div>
                      </div>
                      <div>
                        <IoCheckmarkCircle
                          style={
                             step ===7 || step===9 || step === 11 ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="strip">
                      <div>
                        <IoSettings
                          style={
                                step ===9 || step===11  ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <p className="data-text color-light">Departments</p>
                        </div>
                      </div>
                      <div>
                        <IoCheckmarkCircle
                          style={
                               step ===9 || step===11  ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="strip">
                      <div>
                        <IoSettings
                          style={
                                step ===11   ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <p className="data-text color-light">Structure Mapping</p>
                        </div>
                      </div>
                      <div>
                        <IoCheckmarkCircle
                          style={
                               step ===11   ? { color: "green" } : { color: "gray" }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="text-center">
              <Button
                className="btn-large mt-3 w-50"
                appearance="primary"
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  );
};

export default OrganisationStructure;
