import React, { useState } from "react";
import { Button, Modal } from "rsuite";

function ConfirmDelete({
  deleteObject,
  handledeleteObject,
  setOpenDeleteModal,
  openDeleteModal,
}) {
  return (
    <div>
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title style={{ color: "red" }}>
            {" "}
            Delete Confirmation !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteObject && deleteObject?.children?.length > 0 ? (
            <p>
              <h5> Please Delete Child First ! </h5>
            </p>
          ) : (
            <p>
              <h5>Are you sure you want to delete {deleteObject?.name} ! </h5>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => handledeleteObject()}
            appearance="primary"
            disabled={deleteObject?.children?.length > 0 ? true : false}
          >
            Ok
          </Button>
          <Button onClick={() => setOpenDeleteModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmDelete;
