import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input } from "rsuite";
import { TiDelete } from "react-icons/ti";
import { useForm } from "react-hook-form";

const GSTNumberOfOrganisation = ({ setStep }) => {
  const [gst, setGst] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleGstNumber = () => {
     setStep((prev)=> prev + 1)

  };



  return (
    <div>
      <div className="login-bg-new-img">
      <div className="login-page-modals">
        <div className="login-inner-modal modal-2 new-width">
          <div className="back-btn">
            <a href="#" className="color-light"  onClick={() => setStep((prev)=> prev - 1)}>
              <IoIosArrowBack />
              Back
            </a>
          </div>
          <h3 className="mt-3">
            {" "}
            GST number of your
            <br /> organisation{" "}
          </h3>
          <p className="my-3">Please enter your GST number to continue.</p>
          <form onSubmit={handleSubmit(handleGstNumber)}>
            <div className="n-width">
              <div className="align-items-end d-flex">
                <div className="w-100">
                  <label>GST number</label>
                  {/* <Input
                  style={{ width: 300 }}
                  placeholder="Enter GST number of your organisation"
                /> */}
                  <input
                    type="text"
                    placeholder="Enter GST number of your organisation"
                    className="form-control"
                    {...register("gst", {
                      // required: "Gst Number is Required",
                    })}
                  />
                </div>
              </div>
              <span className="pt-2 d-block requird-color">
                {errors.gst && errors.gst.message}
              </span>

              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  // onClick={() => setStep(3)}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default GSTNumberOfOrganisation;
