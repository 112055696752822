import { PiUserThin } from "react-icons/pi";
import { BsPersonArmsUp } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";


export const navSetup = [
    {
        title:"Company",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Company",
              link: "/Company",
            },
            {
              title: "Company List",
              link: "/Companylist",
            },
            {
              title: "Company Comp",
              link: "/CompanyComp",
            },
            {
              title: "Configuration",
              link: "/configuration",
            },
          ],

    },
    {
        title:"Employees",
         icon:<LiaUserClockSolid className="new-icn"/>,
        menu: [
            {
              title: "Employees",
              link: "/Employees",
            },
          ],

    },
    {
        title:"Qr Management",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Qr Management",
              link: "/Qrmanagement",
            },
           
          ],

    },
    {
        title:"Region",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Region",
              link: "/Region",
            },
          ],

    },
    {
        title:"Departments",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Departments",
              link: "/Departments",
            },
          
          ],

    },
    {
        title:"Designations",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Designations",
              link: "/Designations",
            },
           
          ],

    },
    {
        title:"Structure Mapping",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Structure Mapping",
              link: "/Structuremapping",
            },
          
          ],

    },
    {
        title:"Client",
        icon:<PiUserThin className="new-icn"/>,
        menu: [
            {
              title: "Client",
              link: "/Client",
            },
           
          ],

    },
   
 
   
    
]