import React, { useEffect } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import { IoIosArrowBack } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";

const SetupOrganisation = ({ step, setStep }) => {
  const navigate = useNavigate();


  useEffect(() => {
    if(step === 15){
        // alert("You have successfully completed the setup process. You can now login to your account.");
        navigate("/dashboard");
    }
  }, [step]);
  return (
    <div>
      <div className="login-bg-new-img">
      <div className="login-page-modals">
        <div className="login-inner-modal">
       

         {
          step === 1 ? <div></div> :

         
           <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => {
                setStep((prev)=> prev - 1);
              }}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>

          }

          <h3>
            Let’s set up your <br className="responsive-br" />
            organisation! 
          </h3>
          <div className="login-select-box">
            <div className="container-fluid p-0">
              <div className="row mt-5">
                <div className="col-sm-4">
                 
                    <div className={`${ step === 4 || step ===12 || step===14 ? 'login-select-box-inner color-green' : 'login-select-box-inner'}`}>
                      <div className="d-flex justify-content-end">
                        <IoCheckmarkCircle  style={step === 12  || step ===4 || step===14 ? { color: "#fff" } : {color:""}}/>
                      </div>
                      <img alt="" src={`${step===4 || step===12 || step===14 ? '/images/home1_White.svg' :'/images/home1.svg'}`} />
                       <p className={`${ step===4 || step===12 || step===14 ? "mini-text color-light mt-4 color-white": "mini-text color-light mt-4" }`}>
                        Company Details
                      </p>
                    </div>
                
                </div>
                <div className="col-sm-4">
                  <div  className={`${ step ===12 || step===14 ? 'login-select-box-inner color-green' : 'login-select-box-inner'}`}>
                    <div className="d-flex justify-content-end">
                      <IoCheckmarkCircle  style={step === 12 || step===14   ? { color: "#fff" } : {color:""}}/>
                    </div>
                  <img className="n-img" alt="" src={`${ step===12 || step===14 ? '/images/Structure-white.svg' :'/images/Structure.svg'}`} />
                    <p className={`${ step===12 || step===14 ? "mini-text color-light mt-4 color-white": "mini-text color-light mt-4" }`}>Structure</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div  className={`${ step ===14 ? 'login-select-box-inner color-green' : 'login-select-box-inner'}`}>
                    <div className="d-flex justify-content-end">
                      <IoCheckmarkCircle  style={step === 14  ? { color: "#fff" } : {color:""}}/>
                    </div>
                  <img alt="" src={`${ step===14 ? '/images/Designations-white.svg' :'/images/Designations.svg'}`} />
                    <p className={`${ step===14 ? "mini-text color-light mt-4 color-white": "mini-text color-light mt-4" }`}>Designations</p>
                  </div>
                </div>
               
              </div>
              <div className="text-center">
                <Button
                  className="btn-large mt-5 mb-4 w-50 mr"
                  appearance="primary"
                  onClick={() => {
                    setStep((prev)=> prev + 1)
                  }}
                >
                 {step === 1 ? <div>Get Started</div> : <div>Next</div>} 
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SetupOrganisation;
