// MultiProgressBar.js
import React from 'react';



const MultiProgressBar = ({ segments }) => {
    const total = segments.reduce((sum, segment) => sum + segment.value, 0);


    return (
        <div className="progress-bar-container">
            {segments.map((segment, index) => (
                <div
                    key={index}
                    className="progress-bar-segment"
                    style={{
                        width: `${(segment.value / total) * 100}%`,
                        backgroundColor: segment.color,
                        borderRadius: "10px",
                        marginRight: "10px",
                        
                    }}
                >
                    {/* <span className="progress-bar-label">{segment.label}</span> */}
                </div>
            ))}
        </div>
    );
};

export default MultiProgressBar;
